@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
  width: 100vw;
  margin: 0; /* Ensure no margin on body */
  padding: 0; /* Ensure no padding on body */
}

nav {
  background-color: rgb(0, 59, 149);
  padding: 5px 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Ensure nav takes full width */
  box-sizing: border-box; /* Include padding in width calculation */
}

.navbar-logo {
  height: 40px; /* Example height, adjust as needed */
  width: auto; /* Maintain aspect ratio */
  margin-right: 5px; /* Space between logo and text */
  vertical-align: middle; /* Align with the text */
  background-color: #ffffff;
  border-radius: 30px;
  padding: 5px;
}

form {
  background-color: rgb(0, 59, 149);
  padding-bottom: -18px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100vw;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;
}

.search-area {
  display: flex;
  align-self: center;
  background-color: rgb(0, 59, 149);
  flex-direction: column;
  width: 100%; /* Ensure search-area takes full width */
  box-sizing: border-box; /* Include padding in width calculation */
  margin: 0; /* Ensure no margin on search-area */
  padding: 0; /* Ensure no padding on search-area */
}

.search {
  background-color: rgb(0, 59, 149);
  padding-bottom: -20px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100vw;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;
}

input {
  padding: 1rem;
  border: 5px solid rgb(255, 183, 0); /* Updated border color */
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: -30px;
  margin-top: 10px;
  font-size: 16px;
  color: #282c34;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Gray shadow */
  box-sizing: border-box; /* Ensure padding and border are included in the height */
}

input:hover {
  box-shadow: 0 4px 8px rgba(255, 183, 0, 0.5); /* Yellow shadow on hover */
}

textarea {
  padding: 5px;
  border: 5px solid rgb(255, 183, 0); /* Updated border color */
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: -30px;
  margin-top: 10px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Gray shadow */
  box-sizing: border-box; /* Ensure padding and border are included in the height */
}

span {
  font-size: 1.25rem;
  color: white;
  font-weight: bold;
  padding: 10px;
  /* background-color: rgb(0, 59, 149); */
  text-align: center;
}

h3 {
  font-weight: 500;
  font-size: 24px;
  margin-right: 20px;
}

button {
  padding: 1.1rem 2rem;
  border: 5px solid rgb(255, 183, 0); /* Updated border color */
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  background-color: #007bff;
  margin-bottom: -30px;
  margin-top: 10px;
  height: 66px;
  width: fit-content;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Gray shadow */
}

button:hover {
  background-color: #006ae1;
}

h2 {
  font-weight: 700;
  margin: 10px;
  font-size: large;
}

ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto; /* Enables horizontal scrolling */
  /* width: 100%; Ensure the ul takes full width of its container */
  white-space: nowrap; /* Prevents wrapping of items */
  margin-top: 50px;
  min-height: 300px;
  gap: 10px;
}

li {
  border-radius: 12px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 500;
  border-color: #d0d1d3;
  width: 600px; /* Fixed width */
  margin-right: 5px; /* Add space between items */
  flex-shrink: 0; /* Prevents the items from shrinking */
}

li p {
  margin-bottom: 5px;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 8px;
  padding: 7px;
  width: fit-content;
  font-size: 14px;
}

.offer-item {
  border-radius: 20px;
  min-height: 220px;
  height: fit;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #d0d1d3;
  margin: 20px;
  background-color: white;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  padding-left: 20px; /* Corrected missing unit */
}

.list-item {
  border-radius: 20px;
  height: 10px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #d0d1d3;
  margin: 10px;
  min-height: 200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-left: 20px; /* Corrected missing unit */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for 3D effect */
}

.booking-detail {
  border-width: 1px;
  border-style: solid;
  border-color: #d0d1d3;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for 3D effect */
  position: relative; /* Change to relative to work with flexbox centering */
  z-index: 50;
  background-color: white; /* Ensure it has a background color */
}

.booking-card {
  border-radius: 20px;
  /* height: 10px; */
  padding: 10px 20px 40px 0px;
  border-width: 1px;
  border-style: solid;
  border-color: #d0d1d3;
  margin: 10px 10px 30px 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-left: 20px; /* Corrected missing unit */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for 3D effect */
}

select {
  margin-left: 10px;
  margin-right: 20px;
  height: 60px;
  margin-bottom: -30px;
  margin-top: 10px;
  font-size: 12px;
  border: 5px solid rgb(255, 183, 0); /* Updated border color */
  border-radius: 10px;
}

.inspiration {
  padding-left: 60px;
  padding-top: 40px;
  background-color: rgb(247, 247, 247);
}

.inspiration-header {
  font-size: large;
  font-weight: 700;
}

.inspiration-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto; /* Enables horizontal scrolling */
  width: 100%; /* Ensure the ul takes full width of its container */
  white-space: nowrap; /* Prevents wrapping of items */
  padding-left: 0; /* No padding on the left */
  margin-left: 0; /* No margin on the left */
}

.inspiration-item {
  border-radius: 20px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #d0d1d3;
  margin-right: 20px;
  background-color: white;
  display: flex;
  width: 323px;
  height: 338px;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative; /* Set position to relative */
  overflow: hidden; /* Ensures nothing spills out, especially for rounded corners */
}

.inspiration-item img {
  position: absolute; /* Position the image absolutely within the li */
  top: 0;
  left: 0;
  width: calc(100% + 20px); /* Extend width to cover padding */
  height: calc(100% + 20px); /* Extend height to cover padding */
  object-fit: cover; /* Ensure the image covers the area without distorting */
}

footer {
  background-color: #282c34;
}

.offer-card {
  border-radius: 20px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #282c34;
  min-width: 66%;
  margin-bottom: 20px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.result-card {
  border-radius: 20px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #282c34;
  min-width: 66%;
  margin-bottom: 20px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.results-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto; /* Enables horizontal scrolling */
  /* width: 100%; Ensure the ul takes full width of its container */
  white-space: nowrap; /* Prevents wrapping of items */
  margin-top: 10px;
  margin-left: 10px;
  min-height: 300px;
  gap: 10px;
}

.offers-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto; /* Enables horizontal scrolling */
  /* width: 100%; Ensure the ul takes full width of its container */
  white-space: nowrap; /* Prevents wrapping of items */
  margin-top: 50px;
  min-height: 300px;
  gap: 10px;
}

p {
  border-bottom: #d5d5d5 solid 1px;
  margin-right: 5px;
  padding-bottom: 4px;
  padding-top: 4px;
}

.offer-card {
  border-radius: 20px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #282c34;
  min-width: 66%;
  margin-bottom: 20px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.vertical {
  background-color: rgb(39, 89, 163);
  color: white;
  align-self: center;
  font-weight: 600;
  padding: 10px 15px;
  border: 2px solid #fff; /* Thicker white border */
  border-radius: 50px;
  margin-bottom: 10px;
}

.ship-card {
  border-radius: 20px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #282c34;
  min-width: 66%;
  margin: 20px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.ship-details {
  border-radius: 20px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #282c34;
  min-width: 66%;
  margin: 20px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.flight-info {
  border-radius: 20px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #282c34;
  min-width: 66%;
  margin: 20px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 20;
}

.operator-card {
  border-radius: 20px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #282c34;
  min-width: 66%;
  margin-bottom: 20px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.results {
  margin: 10vh;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 12px;
}

.order-form {
  position: fixed; /* Fixed positioning to make it stay in place even on scrolling */
  top: 50%; /* Position at half the height of the viewport */
  left: 50%; /* Position at half the width of the viewport */
  transform: translate(
    -50%,
    -50%
  ); /* Shift the modal back by half its width and height to center it */
  width: 80%; /* Set the width as desired, here it's 80% of the viewport width */
  max-width: 600px; /* Maximum width of the modal */
  height: auto; /* Height based on content */
  padding: 20px; /* Padding inside the modal */
  background-color: white; /* Background color of the modal */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for 3D effect */
  z-index: 1000; /* Ensure it sits on top of other content */
  border-radius: 8px; /* Optional: rounded corners */
  overflow: auto; /* Enable scrolling within the modal if content is large */
}

.esim-order-list {
  position: fixed; /* Fixed positioning to make it stay in place even on scrolling */
  top: 50%; /* Position at half the height of the viewport */
  left: 50%; /* Position at half the width of the viewport */
  transform: translate(
    -50%,
    -50%
  ); /* Shift the modal back by half its width and height to center it */
  width: 80%; /* Set the width as desired, here it's 80% of the viewport width */
  max-width: 600px; /* Maximum width of the modal */
  height: auto; /* Height based on content */
  padding: 20px; /* Padding inside the modal */
  background-color: white; /* Background color of the modal */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for 3D effect */
  z-index: 1000; /* Ensure it sits on top of other content */
  border-radius: 8px; /* Optional: rounded corners */
  overflow: auto; /* Enable scrolling within the modal if content is large */
}

.passenger-details {
  position: fixed; /* Fixed positioning to make it stay in place even on scrolling */
  top: 50%; /* Position at half the height of the viewport */
  left: 50%; /* Position at half the width of the viewport */
  transform: translate(
    -50%,
    -50%
  ); /* Shift the modal back by half its width and height to center it */
  width: 80%; /* Set the width as desired, here it's 80% of the viewport width */
  max-width: 700px; /* Maximum width of the modal */
  height: auto; /* Height based on content */
  padding: 30px; /* Padding inside the modal */
  padding-bottom: 50px;
  background-color: white; /* Background color of the modal */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
  border: #282c34 5px solid;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 1000; /* Ensure it sits on top of other content */
  border-radius: 8px; /* Optional: rounded corners */
  overflow: auto; /* Enable scrolling within the modal if content is large */
}

.intermediate-travel-points {
  background-color: #fff;
  position: fixed; /* Position the modal on top of everything else */
  top: 50%; /* Center the modal vertically */
  left: 50%; /* Center the modal horizontally */
  transform: translate(
    -50%,
    -50%
  ); /* Adjust the position to be exactly centered */
  z-index: 1000; /* Ensure the modal is on top of other elements */
  padding: 20px; /* Add some padding for better appearance */
  border-radius: 8px; /* Optional: Add rounded corners */
  border: #282c34 2px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
  color: black; /* Optional: Set text color to white for better contrast */
  max-width: 90%; /* Optional: Limit the width of the modal */
  max-height: 90%; /* Optional: Limit the height of the modal */
  overflow-y: auto; /* Optional: Add scroll if content overflows */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  font-family: "Montserrat", sans-serif;
}

thead tr {
  color: black;
  text-align: left;
  font-weight: bold;
  background-color: #d5d5d5;
}

tr {
  cursor: pointer;
}

tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

th,
td {
  padding: 12px 15px;
}

.booking-form {
  min-height: 100px;
  padding: 40px;
  margin: 20px;
  background-color: #ffff;
  border: #b2b2b2 1px solid;
  border-radius: 12px;
}

.booking-response {
  min-height: 100px;
  padding: 40px;
  margin: 20px;
  background-color: #ffff;
  border: #b2b2b2 1px solid;
  border-radius: 12px;
}

.driver-locations {
  font-size: 24px;
  font-weight: 700;
  margin: 20px;
  border-bottom: none;
}

.location-search {
  margin-left: 20px;
}

.location-item {
  margin-left: 20px;
}
.order-success {
  position: fixed; /* Fixed positioning to make it stay in place even on scrolling */
  top: 50%; /* Position at half the height of the viewport */
  left: 50%; /* Position at half the width of the viewport */
  transform: translate(
    -50%,
    -50%
  ); /* Shift the modal back by half its width and height to center it */
  width: 80%; /* Set the width as desired, here it's 80% of the viewport width */
  max-width: 700px; /* Maximum width of the modal */
  height: auto; /* Height based on content */
  padding: 30px; /* Padding inside the modal */
  padding-bottom: 50px;
  color: white;
  background-color: rgb(0, 59, 149);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
  border: #282c34 5px solid;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 1000; /* Ensure it sits on top of other content */
  border-radius: 8px; /* Optional: rounded corners */
  overflow: auto; /* Enable scrolling within the modal if content is large */
}

/* Autosuggest container */
.react-autosuggest__container {
  position: relative;
}

/* Autosuggest input */
.react-autosuggest__input {
  padding: 1rem;
  border: 5px solid rgb(255, 183, 0); /* Updated border color */
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: -30px;
  margin-top: 10px;
  font-size: 16px;
  color: #282c34;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Gray shadow */
  box-sizing: border-box; /* Ensure padding and border are included in the height */
}

/* Autosuggest suggestions container */
.react-autosuggest__suggestions-container {
  display: none;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: 'Arial', sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

/* Autosuggest suggestions container when open */
.react-autosuggest__suggestions-container--open {
  display: block;
}

.react-autosuggest__suggestions-list {
  list-style-type: none;
  font-size: 12px;
  display: flex;
  flex-direction: column; /* Ensure suggestions are listed vertically */
  width: 200px;
}

/* Autosuggest suggestion */
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  width: 200px;
}

/* Autosuggest suggestion when highlighted */
.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.suggestion {
  width: 100px;
}